import api from './api'

export default {
    add(data){
        return api.execute(`post`,`/ts/add`,data)
    },
    tsget(data){
        return api.execute(`post`,`/ts/get`,data)
    },
    tsremove(data){
        return api.execute(`post`,`/ts/remove`,data)
    },
    modify(data){
        return api.execute(`post`,`/ts/modify`,data)
    },
    tsmanagerget(data){
        return api.execute(`post`,`/manager/tsources`,data)
    },
}
