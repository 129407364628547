<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">

        <h4 v-if="edit" class="color-light ml-2 page_title"><span class="ul_text">EDI</span>T TRAFFIC SOURCE</h4>
        <h4 v-else class="color-light ml-2 page_title"><span class="ul_text">NE</span>W TRAFFIC SOURCE</h4>
        <div class="card-header-actions float-right" @click="openModel()">
          <small class="text-white ml-2"><CIcon name="cil-info" /></small>
        </div>
      </CRow> 
      <div class="tsinfo col-md-8">
      <CRow class="ml-2 mt-3">
        <strong class="col-md-10"
          >Traffic Source Name :&nbsp;<span style="color: red">*</span></strong
        >
      </CRow>
      <CRow class="ml-2 mt-1">
        <CInput
          class="col-md-10"
          size="sm"
          id="input-1"
          v-model="mainObject.tsname"
          type="text"
          required
          placeholder="Your Traffic Source Name"
          :state="validatetsname"
          maxlength="100"
        ></CInput>
        <small
          class="col-md-12"
          style="margin-top: -15px"
          v-if="!validatetsname"
          >Please add a name for the traffic source which will help you identify
          it later.
        </small>
      </CRow>

      <CRow class="col-md-10 ml-2 mt-2">
        <label
          ><strong
            >Conversion Postback :&nbsp;<span style="color: red"
              >*</span
            ></strong
          >
          <small
            class="ml-2"
            style="color: blue"
            title="Show Macros"
            @click="convcollapse = !convcollapse"
            >Add Macros</small
          >
        </label>
      </CRow>
      <CRow class="link col-md-12">
        <CCollapse :show="convcollapse" class="ml-4">
          <small v-for="(value, index) in macros" style="background:#e6e6e6;">
            <label class="p-1" @click="addMacrosconv(value)">{{value}}</label>
          </small>
          </CCollapse>
      </CRow>
      <CRow class="ml-2">
        <CInput
          class="col-md-10 url_text"
          size="sm"
          id="input-2"
          v-model="mainObject.conversionUrl"
          type="text"
          required
          placeholder="Your postback URL"
          @input="checkButton(mainObject.conversionUrl,'url')"
          :state="validateconurl"
          maxlength="2000"
        ></CInput>
        <small
          class="col-md-8"
          style="margin-top: -15px"
          :state="validateconurl"
        >
          postback for the traffic source is required so you can report their
          payouts for each conversion you get from their traffic
        </small>
      </CRow>
      <CRow class="ml-2 mt-2">
        <label
          ><strong class="col-md-10">
            Post Conversion Postback URL <i>(optional)</i>
          </strong>
          <small
            class="ml-2"
            style="color: blue"
            title="Show Macros"
            @click="pconvcollapse = !pconvcollapse"
            >Add Macros</small
          >
        </label>
      </CRow>
      <CRow class="link col-md-12">
        <CCollapse :show="pconvcollapse" class="ml-4">
          <small v-for="(value, index) in pmacros" style="background:#e6e6e6;">
            <label class="p-1" @click="addMacrospconv(value)">{{value}}</label>
          </small>
          </CCollapse>
      </CRow>
      <CRow class="ml-2">
        <CInput
          class="col-md-10 url_text"
          size="sm"
          id="input-3"
          v-model="mainObject.postConvPostUrl"
          @input="checkButton(mainObject.postConvPostUrl,'purl')"
          type="text"
          required
          placeholder="Your post conversion postback URL"
          maxlength="2000"
        ></CInput>
      </CRow>
      <CRow v-if="edit" class="ml-2 col-md-12 pb-2">
        <label
          ><strong class="">
            Status:
          </strong></label>
        <CSwitch
              class="ml-3"
              color="success"
              :checked="mainObject.status"
              shape="pill"
              @update:checked="statusUpdate"
            />
      </CRow>
      </div>
    </CCard>

    <CRow class="mt-3 mb-5">
      <CCol class="col-md-2 text-left">
        <div class="submit_ts">
        <CButton type="submit" v-on:click="onSubmit" color="success">{{
          edit ? "Save" : "Submit"
        }}</CButton>
      </div>
      <div style="display:none;" id="closeModal" @click="$emit('tsmodal')">
        <CButton  variant="" color="danger">Close modal</CButton>
      </div>
      </CCol>
    </CRow>
    <!-- Show Model -->
    <CModal
      title="Traffic Source"
      :show.sync="show_details"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <ts-info></ts-info>
      <template #footer>
        <CButton @click="show_details = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
    <vue-tour :name="'ts'"></vue-tour>
  </div>
</template>

 <script>
import TrafficService from "../../services/trafficsourceControllservice";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VueTour from "../../components/vueTour.vue";
import TsInfo from './TsInfo.vue';
import HelperFunction from "./../../helper/helperFunction";
export default {
  name: "TrafficSource",
  props:{
    edit_tsdata:Object,
    ts_modal:Boolean,
  },
  components: {VueTour,TsInfo},
  computed: {
    validatetsname() {
      if (this.mainObject.tsname == "") {
        return false;
      } else if(HelperFunction.validateName(this.mainObject.tsname,100) == false) {
        this.mainObject.tsname = ''
        return false;
      }else{
        return true;
      }
    },
    validateconurl() {
      if (
        this.mainObject.conversionUrl == "" ||
        this.validateUrl(this.mainObject.conversionUrl) != true
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch:{
      ts_modal(value){
        this.edit = false;
        this.mainObject= {tsname: "",conversionUrl: "",postConvPostUrl: ""}
        if(value == true){
          if(window.localStorage.getItem('guide_tool') == 'true'){
            this.$tours['ts'].start()
          }
          var el = document.getElementsByClassName('.focus_guide')
            for (let i = 0; i < el.length; i++) {
              el[i].classList.remove('focus_guide');
            }
          if(this.edit_tsdata){
            this.mainObject={}
            this.mainObject = this.edit_tsdata;
            this.edit = true;
          }
        }
      }
    },
  data() {
    return {
      convcollapse: false,
      pconvcollapse: false,
      show_details: false,
      edit: false,
      show_box: false,
      data: "",
      user: "",
      user_status: false,
      mainObject: {
        tsname: "",
        conversionUrl: "",
        postConvPostUrl: "",
      },
      tsname_txt: true,
      conversionUrl_txt: false,
      postConvPostUrl_txt: false,
      macros:['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12','rev','token','external_id'],
      pmacros:['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12','rev','token','external_id'],
    };
  },
  async mounted() {
    if(window.localStorage.getItem('guide_tool') == 'true'){
    this.$tours['ts'].start()
    var element = document.querySelector('.traffic-source');
    if(element){element.classList.remove('focus_guide');}
    var classes = ['.create-offer','.traffic-source','.create-campaign','.sdk-menu','.report-menu','.campaign-list']
        classes.map((t)=> {
          var ele = document.querySelector(t);
          if(ele){ele.classList.remove('focus_guide');}
        })
        this.$tours['sidebar'].stop()
  }
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true"
    ) {
      this.$router.push("/");
    }
    if (window.localStorage.getItem("user") == "null") {
      alert("Please Enter User Name and Password");
      this.$router.push("/pages/login");
    } else if(window.localStorage.getItem("user") == 'csp'){
          console.log('new ts')
          return;
      }
        else {
      if (this.$root.$data.edit == true) {
        this.mainObject = this.$root.$data.traffic;
        this.$root.$data.edit = false;
        this.edit = true;
      }
      // this.mainObject.useremail = window.localStorage.getItem("email");
      this.user_status = window.localStorage.getItem("status");
    }
    this.checkButton(this.mainObject.conversionUrl,'url')
    this.checkButton(this.mainObject.postConvPostUrl,'purl')
  },
  methods: {
    checkButton(url,txt){
      var params = ['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12','rev','token','external_id']
      for(let e in params) {
        // match value from array and remove it
        if( url.match(new RegExp('{'+params[e]+'}')) ){
          delete params[e]
        }
      }
      switch(txt){
        case 'url':
          this.macros = params
          break;
        case 'purl':
          this.pmacros = params
          break;
      } 
      },
    addMacrosconv(data) {
      // var str1 = "?";
      // for (var i = 0; i < this.mainObject.conversionUrl.length; i++) {
        // if (this.mainObject.conversionUrl.charAt(i) == "?") {
          // var str1 = "&";
        // }
      // }
      var str3 = "{" + data + "}";
      // let url_text = str1.concat(str3);
      let url_text = str3;
      this.mainObject.conversionUrl =
        this.mainObject.conversionUrl.concat(url_text);
        this.checkButton(this.mainObject.conversionUrl,'url')
    },
    addMacrospconv(data) {
      // var str1 = "?";
      // for (var i = 0; i < this.mainObject.postConvPostUrl.length; i++) {
      //   if (this.mainObject.postConvPostUrl.charAt(i) == "?") {
      //     var str1 = "&";
      //   }
      // }
      var str3 = "{" + data + "}";
      // let url_text = str1.concat(str3);
      let url_text = str3;
      this.mainObject.postConvPostUrl =
        this.mainObject.postConvPostUrl.concat(url_text);
        this.checkButton(this.mainObject.postConvPostUrl,'purl')
    },
    openModel() {
      this.show_details = true;
      console.log("open model");
    },
    validateUrl(str) {
      if(str){
          var res = str.match(/^((http(s)?):\/\/)?([a-z0-9]+:[a-z0-9]+@)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
          if(res == null){
              return false;
          }else{
          return true;
          }
      }
    },
    async onSubmit() {
      try {
        if (
          this.mainObject.conversionUrl == "" ||
          this.mainObject.tsname == ""
        ) {
          Swal.fire({
            title: "Error!",
            text: "Please fill all required fields",
            icon: "warning",
            confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
          });
          //this.errorNotify("Error", "Please field all required fields", "error");
        } else {
          if (
            (this.validateUrl(this.mainObject.conversionUrl) == true &&
              (this.mainObject.postConvPostUrl == '' || this.validateUrl(this.mainObject.postConvPostUrl)) == true)
          ) {
            // this.errorNotify("Success", "Correct URL", "success");
            if (this.edit) {
              let updated_data = {
                tsid: this.mainObject._id,
                changePayload: this.mainObject,
              };
              let response = await TrafficService.modify(updated_data);

              if (response.result == true) {
                Swal.fire({
                  title: "Success!",
                  text: "data updated successfully",
                  icon: "success",
                  confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                });
                //this.errorNotify("Success", "data updated successfully", "success");
                // this.$router.push("/ts/tslist");
              } else {
                Swal.fire({
                  title: "Error!",
                  text: response.message,
                  icon: "error",
                  confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                });
              }
              this.edit=false
            } else {
              let response = await TrafficService.add(this.mainObject);
              // .then(res => {
              //   this.errorNotify("Success", res.message, "success");
              // });this.$emit('ready', true)
              if (response.result == true) {
                Swal.fire({
                  title: "Success!",
                  text: response.message,
                  icon: "success",
                  confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                });
                if(window.localStorage.getItem('guide_tool') == 'true'){
                var el = document.querySelector('#next-btn');
                if(el){el.click() }
                }
                // else{this.$router.push("/ts/tslist");}
                //this.errorNotify("Success", response.message, "success");
              } else {
                Swal.fire({
                  title: "Error!",
                  text: response.message,
                  icon: "error",
                  confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                });
                //this.errorNotify("Error", "Traffic Source Name already exist!", "danger");
              }
            }
            this.$emit('tsmodal')
          } else {
            Swal.fire({
              title: "Error!",
              text: "Wrong URL",
              icon: "warning",
              confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
            });
          }
        }
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    statusUpdate() {
      var status = true
        if (this.mainObject.status == true) {
          status = false;
        } else {
          status= true;
        }
        this.mainObject.status = status
    }
  },
};
</script>
 
 <style scoped>
.url_text {
  font-family: "Oswald", sans-serif;
  color: #000;
  font-weight: 600;
}
.link {
  color: blue;
}
</style>